import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Stack,
  styled,
  Typography,
  Tabs,
  Tab,
  Avatar,
} from '@mui/material';
import UserLayout from 'src/layouts/UserLayout';
import { clearProfileInfo, getProfileInfo, getProjects, searchUser, setProject } from '../../store/actions/application';
import ProfileSection from './ProfileSection';
import { getDocumentOfMember, getMembersVizitByProject, getUserVizitByProject } from 'src/store/actions/vizit';
import { CompanyForm } from '../../components/company-form';
import VizitSection from 'src/components/vizit-section';
import AddMemberSection from 'src/components/vizit-section/AddMemberSection';
import AccreaditionSection from './AccreaditionSection';
import VizitSearchModal from 'src/components/vizit-search-modal';
import ConfirmModal from 'src/components/confirm-modal';
import { DOCUMENT_TYPES, PRIORITY_DOCUMENT_TYPE } from 'src/utils/constant';
import Document from 'src/components/document';
import Order from 'src/components/order';
import Loader from 'src/components/loader';
import ManageMeetings from 'src/components/meetings-manage';
import ProfileInvitations from './ProfileInvitations';
import eventBus, { EVENTS } from 'src/utils/eventBus';
import UserIcon from 'src/layouts/components/UserIcon';
import UserStatus from 'src/components/status/UserStatus';
import UserActivityTimeline from 'src/components/user-activity-timeline';
import { useAbility } from 'src/hooks/useAbility';
import { ACCESSES } from 'src/utils/ability';
import { useQuery } from 'react-query';
import { getFullName } from 'src/utils/common';
import queryString from 'query-string';
import { useProjectSettings } from 'src/hooks';
import ImageCropperModal from 'src/components/modal/ImageCropperModal';
import { ProfileProvider } from '../../context/ProfileContext';
import ProfileDocuments from './ProfileDocuments';
import QRCode from 'react-qr-code';

export const HeaderSection = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 600,
  lineHeight: '24px',
  textTransform: 'uppercase',
  padding: '16px 0',
  color: theme.palette.text.primary,
}));

export default function ProfilePage({ isModal, id }) {
  const params = useParams();
  const navigate = useNavigate();
  const ability = useAbility();
  const location = useLocation();
  const projectUuid = queryString.parse(location.search)?.projectUuid;

  const currentProject = useSelector((state) => state.application.currentProject);
  const companies = useSelector((state) => state.application.companies);

  const profileInfo = useSelector((state) => state.application.profileInfo);
  const documents = useSelector((state) => state.application.documents);
  const orders = useSelector((state) => state.application.orders);
  const projects = useSelector((state) => state.application.projects);
  const isStaff = useSelector((state) => state.auth.isStaff);
  console.log(currentProject, profileInfo);
  const [isLoading, setIsLoading] = useState(true);
  const [userVizit, setUserVizit] = useState(null);
  const [membersVizits, setMembersVizits] = useState([]);
  const [isLoadingVizit, setIsLoadingVizit] = useState(false);
  const [showUserVizit, setShowUserVizit] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const [members, setMembers] = useState([]);
  const [avatarFile, setAvatarFile] = useState(null);
  const [activeTab, setActiveTab] = useState('profile');

  const onlyReg = ability.can(ACCESSES.BADGE_REG_EDIT_ONLY) && isStaff;
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  useQuery(['getProjects'], getProjects);

  useEffect(() => {
    if (!projectUuid && currentProject) {
      navigate(location.pathname + `?projectUuid=${currentProject.uuid}`, { replace: true });
    }
  }, [location, currentProject]);

  const userUuid = params.id || id;
  const avatarURL = useMemo(() => {
    if (avatarFile) {
      return URL.createObjectURL(avatarFile);
    }
    return null;
  }, [avatarFile]);
  const _orders = currentProject
    ? orders
        .filter((order) => order.projectUuid === currentProject.uuid)
        .map((o) => ({ ...o, project: projects.find((p) => p.uuid === o.projectUuid) }))
    : orders;

  useProjectSettings({ projectUuid: currentProject?.uuid });

  const fetchAvatar = async () => {
    const response = await getDocumentOfMember(profileInfo.uuid, DOCUMENT_TYPES.AVATAR, currentProject?.uuid);
    if (response) {
      setAvatarFile(new File([response], 'avatar'));
    } else {
      setAvatarFile(null);
    }
  };

  useEffect(() => {
    if (currentProject && profileInfo) {
      fetchAvatar();
    }
  }, [profileInfo, currentProject]);

  useEffect(() => {
    getProfileInfo(userUuid, projectUuid).then(() => setIsLoading(false));
    return clearProfileInfo;
  }, [userUuid, projectUuid]);

  const fetchData = (projectUuid, userUuid) => {
    Promise.all([getUserVizitByProject(projectUuid, userUuid), getMembersVizitByProject(projectUuid, userUuid)]).then(
      ([userVizit, membersVizit]) => {
        setUserVizit(userVizit);
        setMembersVizits(membersVizit);
        setIsLoadingVizit(false);
      }
    );
  };

  const fetchMembers = async (userUuid) => {
    if (currentProject) {
      const members = await searchUser({ accreditedBy: userUuid, limit: 1000, projectUuid: projectUuid });
      setMembers(members);
    }
  };

  useEffect(() => {
    if (projectUuid && userUuid) {
      setIsLoadingVizit(true);
      fetchData(projectUuid, userUuid);
      fetchMembers(userUuid);
    }
  }, [projectUuid, userUuid]);

  const handleReloadProfile = () => {
    if (projectUuid && userUuid) {
      setIsLoading(true);
      getProfileInfo(userUuid, projectUuid).then(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    eventBus.on(EVENTS.RELOAD_PROFILE, handleReloadProfile);
    return () => {
      eventBus.off(EVENTS.RELOAD_PROFILE, handleReloadProfile);
    };
  }, [userUuid, projectUuid]);
  const Layout = isModal ? Box : UserLayout;
  return (
    <ProfileProvider>
      <Layout isModal={isModal} showExtraNavItems={true} useProjectUuidQuery={true}>
        <Stack gap="24px">
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                <Stack alignItems="flex-start" gap="10px" flex={1}>
                  {!isModal && (
                    <Button
                      size="small"
                      color="secondary"
                      startIcon={<UserIcon icon="mdi:chevron-left" />}
                      sx={{
                        fontSize: '0.75rem',
                        lineHeight: '32px',
                        '& .MuiButton-startIcon': {
                          marginRight: '4px',
                        },
                      }}
                      onClick={() => navigate(-1)}
                    >
                      Вернуться к списку
                    </Button>
                  )}
                  {currentProject && profileInfo && (
                    <>
                      <Stack direction="row" alignItems="center" gap="12px" flexWrap="wrap">
                        <Stack gap="8px" alignItems="center">
                          <Avatar
                            src={avatarURL}
                            sx={{ width: '86px', height: '86px', cursor: avatarURL ? 'pointer' : 'default' }}
                            onClick={() => {
                              if (avatarURL) {
                                eventBus.emit(EVENTS.OPEN_VIEW_IMAGE_MODAL, {
                                  images: [{ src: avatarURL }],
                                });
                              }
                            }}
                          />
                        </Stack>
                        {profileInfo && (
                          <Stack>
                            <Typography variant="body1">{getFullName(profileInfo)}</Typography>
                            <Typography variant="caption">{profileInfo.email}</Typography>
                            <Typography variant="caption">
                              <b>
                                {
                                  currentProject.clientGroups.find(
                                    (el) => el.id === profileInfo.projectSettings[projectUuid]?.mainRoleId
                                  )?.name['ru_RU']
                                }
                              </b>
                            </Typography>
                          </Stack>
                        )}
                      </Stack>

                      <Stack direction="row" gap="4px">
                        <UserStatus
                          isAccredited={profileInfo?.projectSettings?.[currentProject?.uuid]?.isAccredited}
                          readyToAccreditate={profileInfo?.projectSettings?.[currentProject?.uuid]?.readyToAccreditate}
                        />
                        <Stack flexWrap="wrap" direction="row" gap="4px">
                          <Chip
                            color="info"
                            label={`Создал анкету ${
                              profileInfo?.accreditedBy ? 'по основному пользователю' : 'самостоятельно'
                            }`}
                          />
                          {profileInfo?.projectSettings?.[currentProject?.uuid]?.isGivenMerch && (
                            <Chip color="warning" label="Пользователь уже получил мерч" />
                          )}
                          {profileInfo?.projectSettings?.[currentProject?.uuid]?.isBadgePrinted !== null && (
                            <Chip
                              color={
                                profileInfo?.projectSettings?.[currentProject?.uuid]?.isBadgePrinted
                                  ? 'success'
                                  : 'info'
                              }
                              label={
                                profileInfo?.projectSettings?.[currentProject?.uuid]?.isBadgePrinted === true
                                  ? 'Распечатан бейдж'
                                  : 'Брак печати'
                              }
                            />
                          )}
                          {profileInfo?.projectSettings?.[currentProject?.uuid]?.isCurrentBadgeBlocked === true && (
                            <Chip color="error" label="Бейдж заблокирован" />
                          )}
                        </Stack>
                      </Stack>
                    </>
                  )}
                </Stack>
                <Card sx={[{ p: '10px' }, isModal && { boxShadow: 'none' }]}>
                  <QRCode value={document.location.href} size={120} />
                </Card>
              </Stack>
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Профиль" value={'profile'} />

                <Tab label="Анкета" value={'anketa'} />
                <Tab label="Компания" value={'company'} />
                <Tab label="Визитка" value={'visit'} />
                <Tab label="Встречи" value={'meetings'} />
                <Tab label="Документы и заказы" value={'docandorder'} />
                <Tab label="Действия пользователя" value={'actions'} />
              </Tabs>
            </CardContent>
          </Card>

          {/* ProfileSection */}
          {activeTab === 'profile' && !onlyReg && (
            <>
              <ProfileSection
                userUuid={userUuid}
                isLoading={isLoading}
                members={members}
                membersVizits={membersVizits}
                fetchMembers={fetchMembers}
                avatarFile={avatarFile}
                setAvatarFile={setAvatarFile}
              />

              <VizitSearchModal />
              <ConfirmModal />
              <ImageCropperModal />
            </>
          )}

          {/* Register Section */}
          {activeTab === 'anketa' && !isLoading && profileInfo && (
            <AccreaditionSection avatarFile={avatarFile} setAvatarFile={setAvatarFile} />
          )}

          {isLoading && <Loader />}

          {/* Company Section */}
          {activeTab === 'company' && !onlyReg && (
            <Card>
              <CardContent>
                {!companies?.[0] && !showCompany && ability.can(ACCESSES.EDIT_COMPANY) && (
                  <Button variant="contained" onClick={() => setShowCompany(true)}>
                    Добавить компанию
                  </Button>
                )}
                {(showCompany || companies?.[0]) && (
                  <CompanyForm userId={userUuid} isLocked={ability.cannot(ACCESSES.EDIT_COMPANY)} />
                )}
              </CardContent>
            </Card>
          )}

          {/* Vizit Section */}
          {activeTab === 'visit' && currentProject && !onlyReg && (
            <Card>
              <CardContent>
                {!userVizit && !showUserVizit && ability.can(ACCESSES.MANAGE_VIZIT) && (
                  <Button variant="contained" onClick={() => setShowUserVizit(true)}>
                    Добавить визитную карточку
                  </Button>
                )}
                {(showUserVizit || userVizit) && (
                  <VizitSection
                    projectUuid={currentProject.uuid}
                    userUuid={userUuid}
                    isLoadingVizit={isLoadingVizit}
                    vizits={userVizit}
                    setVizits={setUserVizit}
                    membersVizits={membersVizits}
                    setMembersVizits={setMembersVizits}
                    isLocked={ability.cannot(ACCESSES.EDIT_VIZIT)}
                    onReloadVizit={() => fetchData(projectUuid, userUuid)}
                  />
                )}
              </CardContent>
            </Card>
          )}

          {/* Members Section */}
          {activeTab === 'meetings' && currentProject?.uuid && userVizit && !onlyReg && (
            <Card>
              <CardContent>
                <AddMemberSection
                  projectUuid={currentProject.uuid}
                  userUuid={userUuid}
                  isLoadingVizit={isLoadingVizit}
                  vizits={userVizit}
                  setVizits={setUserVizit}
                  membersVizits={membersVizits}
                  setMembersVizits={setMembersVizits}
                  isProfile
                />
              </CardContent>
            </Card>
          )}

          {/* Meetings Section */}
          {activeTab === 'meetings' && currentProject?.uuid && ability.can(ACCESSES.MANAGE_MEETING) && !onlyReg && (
            <ManageMeetings projectUuid={currentProject.uuid} userUuid={userUuid} />
          )}

          {/* Invitations Section */}
          {activeTab === 'profile' && profileInfo && currentProject?.uuid && !onlyReg && (
            <ProfileInvitations userUuid={profileInfo.uuid} projectUuid={currentProject.uuid} />
          )}

          {/* Documents and Orders Section */}
          {activeTab === 'docandorder' && profileInfo && !onlyReg && (
            <>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={6}>
                  <Card sx={{ height: '100%' }}>
                    <CardContent>
                      <Typography variant="h6">Документы</Typography>
                      <ProfileDocuments documents={documents} userUuid={userUuid} projectUuid={currentProject?.uuid} />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Card sx={{ height: '100%' }}>
                    <CardContent>
                      <Typography variant="h6" marginBottom="16px">
                        Заказы
                      </Typography>
                      <Stack gap="16px">
                        {_orders.map((item, i) => (
                          <React.Fragment key={i}>
                            {i !== 0 && <Divider />}
                            <Order
                              order={item}
                              userName={getFullName(profileInfo)}
                              members={members}
                              profileInfo={profileInfo}
                            />
                          </React.Fragment>
                        ))}
                        {_orders.length === 0 && <div>Нет заказы</div>}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}

          {/* User activity timeline */}
          {activeTab === 'actions' && !onlyReg && <UserActivityTimeline isModal userUuid={profileInfo?.uuid} />}
        </Stack>
      </Layout>
    </ProfileProvider>
  );
}
