import { Box, Button, Card, CardContent, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Select from 'src/@core/components/mui/select';
import Loader from 'src/components/loader';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { request } from 'src/utils/request';
import ScubForm from './ScubForm';
import UserActivityTimeline from 'src/components/user-activity-timeline';
import ScudSettings from './ScudSettings';
import OfflineScudSettings from './OfflineScudSettings';
import { useSelector } from 'react-redux';

function ScubSettings({ projectUuid }) {
  const currentProject = useSelector((state) => state.application.currentProject);
  const [isOnline, setIsOnline] = useState(!!currentProject.onlineScud);

  return isOnline ? <ScudSettings projectUuid={projectUuid} /> : <OfflineScudSettings projectUuid={projectUuid} />;
}

export default ScubSettings;
