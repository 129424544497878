import { Box, Button, Card, CardContent, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Select from 'src/@core/components/mui/select';
import Loader from 'src/components/loader';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { request } from 'src/utils/request';
import ScubForm from './ScubForm';
import UserActivityTimeline from 'src/components/user-activity-timeline';

function ScudSettings({ projectUuid }) {
  const [roles, setRoles] = useState([]);
  const [currentEvent, setCurrentEvent] = useState();
  const [scubEvents, setScubEvents] = useState([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
  const [showHistoryChanges, setShowHistoryChanges] = useState(false);

  const fetchScubData = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const res = await request(`/internal/projects/${projectUuid}/scub`);
    return res?.message;
  };

  const {
    isLoading,
    error,
    data,
    refetch: refetchScubData,
    isFetching,
  } = useQuery(['fetchScubData', projectUuid], fetchScubData, {
    enabled: !!projectUuid,
  });

  const initNecessaryData = async () => {
    try {
      const [resRoles, resScubsEvents] = await Promise.all([
        request(`/roles?projectUuid=${projectUuid}`),
        request(`/internal/projects/events-scub`),
      ]);
      setScubEvents(resScubsEvents.message || []);
      setRoles(resRoles.message || []);
    } catch (error) {
      console.error(error);
      createNotification('Что-то пошло не так', 'error');
    } finally {
      setIsLoadingTemplates(false);
    }
  };

  useEffect(() => {
    initNecessaryData();
  }, []);

  const statusCode = error ? error.code : 200;
  const notFoundSettings = statusCode === 404;

  const handleSyncScub = async () => {
    try {
      await request(`/internal/projects/events-scub`, { method: 'POST' });
      createNotification('Синхронизировать успешно', 'success');
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message?.error);
    }
  };

  useEffect(() => {
    if (data) {
      setCurrentEvent(data?.gateEventId);
    }
  }, [data]);

  return isLoadingTemplates ? (
    <Loader />
  ) : (
    <Card>
      <CardContent>
        <Stack direction="row" alignItems="center" gap="16px">
          <Typography variant="h6" sx={{ whiteSpace: 'nowrap', marginRight: '20px' }}>
            Выберите мероприятие СКУД:
          </Typography>
          <Select
            fullWidth={true}
            label="Мероприятие СКУД"
            options={scubEvents}
            value={currentEvent}
            getOptionLabel={(item) => item.name}
            getOptionValue={(item) => item.id}
            onChange={(value) => {
              refetchScubData();
              setCurrentEvent(value);
            }}
          />
        </Stack>
      </CardContent>

      <Card>
        <CardContent>
          <Box paddingY="20px">
            <Stack alignItems="flex-end">
              <Stack flexDirection="row" gap="8px">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowHistoryChanges(true);
                  }}
                >
                  История изменений
                </Button>
                <Button variant="contained" onClick={handleSyncScub}>
                  Синхронизировать данные СКУД
                </Button>
              </Stack>
            </Stack>
            <Stack gap="16px">
              {!currentEvent && (
                <p style={{ textAlign: 'center', marginTop: '20px' }}>Выберите мероприятие, чтобы продолжить процесс</p>
              )}
              {currentEvent &&
                (isLoading || isFetching ? (
                  <Loader />
                ) : (
                  <ScubForm
                    projectUuid={projectUuid}
                    scubEvents={scubEvents}
                    scubData={data}
                    currentEvent={currentEvent}
                    roles={roles}
                    notFoundSettings={notFoundSettings}
                    refetchScubData={refetchScubData}
                  />
                ))}
            </Stack>
          </Box>
        </CardContent>
      </Card>

      {showHistoryChanges && (
        <Dialog
          open={showHistoryChanges}
          onClose={() => {
            setShowHistoryChanges(false);
          }}
          maxWidth="lg"
          PaperProps={{ sx: { minWidth: { xs: 'calc(100% - 2rem)', md: '900px' } } }}
        >
          <DialogContent sx={{ '&>div': { boxShadow: 'none' } }}>
            <UserActivityTimeline isProjectPage isScub />
          </DialogContent>
        </Dialog>
      )}
    </Card>
  );
}

export default ScudSettings;
