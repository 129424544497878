import { Button, Card, CardContent, Stack, styled, Typography } from '@mui/material';
import { toLower, upperFirst } from 'lodash';
import qs from 'query-string';
import { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from 'src/components/confirm-modal';
import ConfirmSyncProjectModal from 'src/components/confirm-sync-project-modal';
import ConfirmUpdateProjectModal from 'src/components/confirm-update-project-modal';
import { routes } from 'src/constants';
import { useProjectDetail } from 'src/hooks';
import UserLayout from 'src/layouts/UserLayout';
import { setProject } from 'src/store/actions/application';
import { PROJECT_SETTING_TABS } from 'src/utils/constant';
import { request } from 'src/utils/request';
import AnketaSettings from './AnketaSettings';
import AvatarSizeSettings from './avatar-size';
import BDKSettings from './bdk';
import DataSettings from './DataSettings';
import EmailSettings from './email';
import UploadInfoFilesSettings from './info-files';
import ScubSettings from './scub';
import SupportedUserTab from './supported-user';
import TicketSettings from './ticket';
import ProjectChanges from './project-changes';

export const TableHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '24px',
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
}));

function ProjectSettingsPage() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = qs.parse(location.search);
  const activeTab = queryParams.tab || PROJECT_SETTING_TABS.anketa.key;

  const { isLoading, data, refetch: refetchProjectDetail } = useProjectDetail({ projectUuid: uuid });

  // useEffect(() => { //хз зачем тут это было, но перезаписывало старыми данными проекта
  //   if (!isLoading && data) {
  //     setProject(data);
  //   }
  // }, [isLoading, data]);

  const setQueryParams = useCallback(
    ({ ...newParams }) => {
      const newKeys = Object.keys({ ...newParams });
      const query = qs.stringify({
        ...Object.entries(queryParams)
          .filter((set) => newKeys.includes(set[0])) // removing current values, so they can be overwritten by new if new are nulls
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
        ...newParams,
      });
      navigate(`${routes.projectSettings.path.replace(':uuid', uuid)}?${query}`);
    },
    [queryParams, uuid]
  );

  const handleSelectTab = (name) => () => {
    setQueryParams({ tab: name });
  };

  const tabs = Object.keys(PROJECT_SETTING_TABS).map((s) => {
    const tabConfig = PROJECT_SETTING_TABS[s];
    return {
      label: upperFirst(toLower(tabConfig.label)),
      isActive: activeTab === tabConfig.key,
      onSelect: handleSelectTab(tabConfig.key),
    };
  });

  const renderSettingsSection = (activeTab) => {
    switch (activeTab) {
      case PROJECT_SETTING_TABS.anketa.key:
        return <AnketaSettings projectUuid={uuid} />;
      case PROJECT_SETTING_TABS.data.key:
        return <DataSettings projectUuid={uuid} refetchProjectDetail={refetchProjectDetail} />;
      case PROJECT_SETTING_TABS.user.key:
        return <SupportedUserTab projectUuid={uuid} />;
      case PROJECT_SETTING_TABS.ticket.key:
        return <TicketSettings key="ticket" projectUuid={uuid} />;
      case PROJECT_SETTING_TABS.badge.key:
        return <TicketSettings key="badge" projectUuid={uuid} isAdditional={true} />;
      case PROJECT_SETTING_TABS.email.key:
        return <EmailSettings projectUuid={uuid} />;
      case PROJECT_SETTING_TABS.bdk.key:
        return <BDKSettings projectUuid={uuid} />;
      case PROJECT_SETTING_TABS.infoFiles.key:
        return <UploadInfoFilesSettings projectUuid={uuid} />;
      case PROJECT_SETTING_TABS.avatarSize.key:
        return <AvatarSizeSettings projectUuid={uuid} />;
      case PROJECT_SETTING_TABS.changes.key:
        return <ProjectChanges projectUuid={uuid} />;
      default:
        return <></>;
    }
  };

  return (
    <UserLayout showExtraNavItems={true}>
      <Stack gap="24px">
        <Card>
          <CardContent>
            <Stack gap="16px">
              <Typography variant="h6">Настройки проекта</Typography>
              <Stack direction="row" gap="8px" flexWrap="wrap">
                {tabs.map((tab, i) => (
                  <Button
                    key={`tab-${i}`}
                    variant={tab.isActive ? 'contained' : 'text'}
                    color={tab.isActive ? 'primary' : 'secondary'}
                    onClick={tab.onSelect}
                  >
                    {tab.label}
                  </Button>
                ))}
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        {renderSettingsSection(activeTab)}
      </Stack>
      <ConfirmModal />
      <ConfirmUpdateProjectModal />
      <ConfirmSyncProjectModal />
    </UserLayout>
  );
}

export default ProjectSettingsPage;
