import { DOCUMENT_TYPES } from '../constants';
import { request } from '../utils/request';
import qs from 'query-string';

export const userAPIs = {
  getUser: (query) =>
    request(`/internal/users${query ? `?${query}` : ''}`, {
      method: 'GET',
    }),
  mergeUsers: (body) =>
    request(`/internal/users/merge`, {
      method: 'POST',
      body,
    }),
  getDocumentMember: (userUuid, type = DOCUMENT_TYPES.PASSPORT, projectUuid) =>
    request(
      `/internal/users/${userUuid}/documents/file?type=${type}${projectUuid ? `&projectUuid=${projectUuid}` : ''}`,
      {
        method: 'GET',
      },
      false,
      true
    ),
  createVizit: (id, body) =>
    request(`/users/current/projects/${id}/vizits`, {
      method: 'POST',
      body,
    }),
  createUserVizit: (projectUuid, userUuid, body) =>
    request(`/internal/projects/${projectUuid}/users/${userUuid}/vizits`, {
      method: 'POST',
      body,
    }),
  patchUserVizit: (projectUuid, userUuid, body) =>
    request(`/internal/projects/${projectUuid}/users/${userUuid}/vizits`, {
      method: 'PATCH',
      body,
    }),
  updateLogoVizit: (projectUuid, userUuid, body) =>
    request(`/internal/projects/${projectUuid}/users/${userUuid}/vizits`, {
      method: 'PUT',
      body,
    }),
  getMembersVizitsProject: (projectUuid, userUuid) =>
    request(`/internal/projects/${projectUuid}/users/${userUuid}/members`, {
      method: 'GET',
    }),
  createMembersVizitsProject: (projectUuid, userUuid, body) =>
    request(`/internal/projects/${projectUuid}/users/${userUuid}/members`, {
      method: 'POST',
      body,
    }),
  patchMembersVizitsProject: (projectUuid, userUuid, body) =>
    request(`/internal/projects/${projectUuid}/users/${userUuid}/members`, {
      method: 'PATCH',
      body,
    }),
  uploadPhotoMembersVizitsProject: (projectUuid, id, body) =>
    request(`/users/current/projects/${projectUuid}/vizits/members/${id}`, {
      method: 'PUT',
      body,
    }),
  getUserDocuments: (userUuid, queries) => request(`/internal/users/${userUuid}/documents?${qs.stringify(queries)}`),
  uploadAvatarForMemberVizit: (userUuid, body) =>
    request(`/internal/users/${userUuid}/documents`, {
      method: 'PUT',
      body,
    }),
  downloadUserDocument: (userUuid, type = DOCUMENT_TYPES.CONFIRMATION_LETTER, projectUuid) =>
    request(
      `/internal/users/${userUuid}/documents/file?type=${type}${
        projectUuid && !isNaN(projectUuid) ? `&projectUuid=${projectUuid}` : ''
      }`,
      {
        method: 'GET',
      }
    ),
  searchVizits: (projectUuid, userUuid, query) =>
    request(`/internal/projects/${projectUuid}/vizits/search${query ? '?' + query : ''}`, {
      method: 'GET',
    }),
  confirmMeeting: (projectUuid, userUuid, body) =>
    request(`/internal/projects/${projectUuid}/users/${userUuid}/vizits/confirm`, {
      method: 'POST',
      body,
    }),
  getUserMeetings: (projectUuid, userUuid, status = '') =>
    request(`/internal/projects/${projectUuid}/users/${userUuid}/meetings?status=${status}`, {
      method: 'GET',
    }),
  getAllMeetings: (projectUuid, query) =>
    request(`/internal/projects/${projectUuid}/meetings${query ? '?' + query : ''}`, {
      method: 'GET',
    }),
  getTotalMeetings: (projectUuid, query) =>
    request(`/internal/projects/${projectUuid}/meetings/totalCount${query ? `?${query}` : ''}`, {
      method: 'GET',
    }),
  getAvatarMemberVizits: (userUuid) =>
    request(`/users/current/avatars/${userUuid}`, {
      method: 'GET',
    }),
  getUserVizitsProject: (projectUuid, userUuid) =>
    request(`/internal/projects/${projectUuid}/users/${userUuid}/vizits`, {
      method: 'GET',
    }),
  updateMeetingStatus: (projectUuid, meetingUuid, body) =>
    request(`/internal/projects/${projectUuid}/meetings/${meetingUuid}`, {
      method: 'PATCH',
      body,
    }),
  deleteUserVizit: (projectUuid, userUuid) =>
    request(`/internal/projects/${projectUuid}/users/${userUuid}/vizits`, {
      method: 'DELETE',
    }),
  deleteUserCompany: (userUuid) =>
    request(`/internal/users/${userUuid}/companies`, {
      method: 'DELETE',
    }),
  deleteMeeting: (projectUuid, meetingUuid) =>
    request(`/internal/projects/${projectUuid}/meetings/${meetingUuid}`, {
      method: 'DELETE',
    }),
  getSuggestionCompany: (query) =>
    request(`/internal/companies/suggestions${query ? `?query=${query}` : ''}`, {
      method: 'GET',
    }),
  getSuggestionAddress: (query) =>
    request(`/internal/companies/address/suggestions${query ? `?query=${query}` : ''}`, {
      method: 'GET',
    }),
  getInvitations: (userUuid, projectUuid) =>
    request(`/internal/users/${userUuid}/invitations?projectUuid=${projectUuid}`),
  removeFromProject: (userUuid, projectUuid) =>
    request(`/internal/users/${userUuid}/remove/${projectUuid}`, { method: 'POST' }),
  updateVizitStatus: (projectUuid, vizitUuid, status) =>
    request(`/internal/projects/${projectUuid}/vizits/${vizitUuid}?status=${status}`, { method: 'PATCH' }),
  getUserActivities: (userUuid, offset = 0, limit = 10, projectUuid, isScub, isSetting, isOfflineScub) =>
    request(
      `/internal/users/user-activities?${qs.stringify({
        userUuid,
        limit,
        offset,
        projectUuid,
        isScub,
        isSetting,
        isOfflineScub,
      })}`,
      {
        method: 'GET',
      }
    ),
  blockBadge: (projectUuid, userUuid) =>
    request(`/internal/projects/${projectUuid}/users/${userUuid}/block-badge`, { method: 'POST' }),
};
