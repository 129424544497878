import {
  Box,
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grow,
  InputLabel,
  ListItem,
  MenuItem,
  Paper,
  Popper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import Loader from 'src/components/loader';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { request } from 'src/utils/request';
import ScubForm from './ScubForm';
import UserActivityTimeline from 'src/components/user-activity-timeline';
import OfflineScubForm from './OfflineScubForm';
import { projectAPIs } from 'src/services';
import { Controller, useForm } from 'react-hook-form';
import { number, object, string } from 'yup';
import { validatorMessage } from 'src/utils/formValidator';
import { yupResolver } from '@hookform/resolvers/yup';

function OfflineScudSettings({ projectUuid }) {
  const [roles, setRoles] = useState([]);
  const [showHistoryChanges, setShowHistoryChanges] = useState(false);
  const [isGettingBadges, setIsGettingBadges] = useState(false);
  const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);

  const closeBadgesDialog = () => setBadgesDialogOpen(false);

  const fetchScubData = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const resRoles = (await request(`/roles?projectUuid=${projectUuid}`))?.message || [];
    setRoles(resRoles);
    const resCount = (await projectAPIs.getBarcodesCount(projectUuid))?.message || [];

    return resCount.map((el) => {
      const role = resRoles.find((r) => r.id === el.role_id);
      return { ...el, role };
    });
  };

  const {
    isLoading,
    error,
    data,
    refetch: refetchScubData,
    isFetching,
  } = useQuery(['fetchOfflineScubData', projectUuid], fetchScubData, {
    enabled: !!projectUuid,
    staleTime: 0,
  });

  const getBadgesRanges = async ({ quantity, role_id }) => {
    setIsGettingBadges(true);
    try {
      await projectAPIs.getBadgesRanges(projectUuid, { quantity, role_id });
      createNotification('Диапазоны бейджей получены успешно', 'success');
      setBadgesDialogOpen(false);
      refetchScubData();
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message?.error || 'Произошла ошибка');
    } finally {
      setIsGettingBadges(false);
    }
  };

  const {
    control,
    getValues,
    reset,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      object().shape({
        role_id: string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
        quantity: number()
          .positive(validatorMessage.VALUE_IS_POSITIVE)
          .typeError(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
          .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
      })
    ),
  });

  useEffect(() => {
    if (badgesDialogOpen) reset();
  }, [badgesDialogOpen]);

  return (
    <Card>
      <Card>
        <CardContent>
          <Box paddingY="20px">
            <Stack alignItems="flex-end">
              <Stack flexDirection="row" gap="8px">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowHistoryChanges(true);
                  }}
                >
                  История запросов
                </Button>
                <Button
                  disabled={isGettingBadges}
                  startIcon={isGettingBadges && <Loader color="inherit" size="12px" />}
                  variant="contained"
                  onClick={() => setBadgesDialogOpen(true)}
                >
                  Получить новые диапазоны бейджей
                </Button>
              </Stack>
            </Stack>
            <Stack gap="16px">
              {isLoading || isFetching ? (
                <Loader />
              ) : (
                <OfflineScubForm
                  projectUuid={projectUuid}
                  roles={roles}
                  scubData={data}
                  refetchScubData={refetchScubData}
                />
              )}
            </Stack>
          </Box>
        </CardContent>
      </Card>

      {showHistoryChanges && (
        <Dialog
          open={showHistoryChanges}
          onClose={() => {
            setShowHistoryChanges(false);
          }}
          maxWidth="lg"
          PaperProps={{ sx: { minWidth: { xs: 'calc(100% - 2rem)', md: '900px' } } }}
        >
          <DialogContent sx={{ '&>div': { boxShadow: 'none' } }}>
            <UserActivityTimeline isProjectPage isOfflineScub isModal roles={roles} />
          </DialogContent>
        </Dialog>
      )}

      <Dialog
        open={badgesDialogOpen}
        onClose={closeBadgesDialog}
        sx={{
          '& .MuiDialog-paper': {
            width: '80%',
            maxWidth: '600px',
            height: 'auto',
            maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle>Получить новые диапазоны бейджей</DialogTitle>
        <DialogContent sx={{ '&>div': { boxShadow: 'none' } }}>
          <form>
            <Controller
              name={'role_id'}
              control={control}
              render={({ field }) => (
                <FormControl error={errors.role_id} required fullWidth margin="normal" variant="outlined">
                  <InputLabel required>Роль</InputLabel>
                  <Select
                    required
                    inputRef={field.ref}
                    fullWidth={true}
                    label="Роль"
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    error={errors.role_id}
                  >
                    {roles.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <>{option.name.ru_RU}</>
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.role_id?.message && <FormHelperText>{errors.role_id?.message}</FormHelperText>}
                </FormControl>
              )}
            />
            <TextField
              margin="normal"
              required
              label="Количество выпускаемых бейджей"
              fullWidth={true}
              type="number"
              {...register('quantity')}
              error={errors.quantity}
              helperText={errors.quantity?.message}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit(getBadgesRanges)} variant="contained">
            Ок
          </Button>
          <Button onClick={closeBadgesDialog} variant="outlined">
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default OfflineScudSettings;
