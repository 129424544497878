import CustomChip from 'src/@core/components/mui/chip';

const getStatusLabel = (isAccredited, readyToAccreditate) => {
  switch (isAccredited) {
    case false:
      return 'Отклонено';
    case true:
      return 'Аккредитован';
    case null:
    case undefined:
    default:
      switch (readyToAccreditate) {
        case true:
          return 'На проверка';
        default:
          return 'Не готов';
      }
  }
};

const getStatusColor = (isAccredited, readyToAccreditate) => {
  switch (isAccredited) {
    case false:
      return 'error';
    case true:
      return 'success';
    case null:
    case undefined:
    default:
      switch (readyToAccreditate) {
        case true:
          return 'warning';
        default:
          return 'info';
      }
  }
};

export default function UserStatus({ isAccredited, readyToAccreditate }) {
  const label = getStatusLabel(isAccredited, readyToAccreditate);
  const color = getStatusColor(isAccredited, readyToAccreditate);
  return <CustomChip label={label} skin="light" color={color} />;
}
