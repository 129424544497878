import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, Stack, styled, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAbility } from 'src/hooks/useAbility';
import { userAPIs } from 'src/services';
import { editUser, setProject, updateUserByProjectUuid } from 'src/store/actions/application';
import { ACCESSES } from 'src/utils/ability';
import { getLocalizationValue } from 'src/utils/common';
import eventBus, { EVENTS } from 'src/utils/eventBus';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { HeaderSection } from '.';

const ProjectCheckBox = styled(Checkbox)(() => ({ marginRight: 8 }));

const PRIORITY_DOCUMENT_TYPE = {
  photo: 4,
  international_passport: 3,
  confirmation_letter: 2,
  invoice: 1,
};

function ProfileSettings({ userUuid, isBezviz, members, membersVizits, fetchMembers }) {
  const navigate = useNavigate();
  const ability = useAbility();
  const profileInfo = useSelector((state) => state.application.profileInfo);
  const roles = useSelector((state) => state.application.roles);
  const projects = useSelector((state) => state.application.projects);
  const currentProject = useSelector((state) => state.application.currentProject);

  const projectUuid = currentProject?.uuid;
  const projectSettings = profileInfo.projectSettings?.[projectUuid];
  const joinedProjects = projects.filter((p) => profileInfo?.projectUuids?.includes(p.uuid));
  const mainRoleId = currentProject ? projectSettings?.mainRoleId : profileInfo.mainRoleId;
  const role = roles.find((role) => role.id === mainRoleId);

  const initialValues = {
    projectUuids: projects.filter((p) => profileInfo.projectUuids.find((uuid) => uuid === p.uuid)),
    mainRoleId: role?.id || '',
  };

  const handleUpdateUser = ({ isAccredited }) => {
    if (userUuid) updateUserByProjectUuid(userUuid, { isAccredited }, { isUpdateDocuments: true });
  };

  const handleUpdateProject = (projects) => {
    const projectIds = projects.map((p) => p.uuid);
    if (currentProject && !projectIds.includes(currentProject.uuid)) {
      setProject(null);
    }
    editUser({ uuid: profileInfo.uuid, projectIds });
  };

  const handleUpdateMainRole = async (roleId) => {
    await updateUserByProjectUuid(profileInfo.uuid, { mainRoleId: roles.find((role) => role.id === roleId)?.id });
    eventBus.emit(EVENTS.RELOAD_PROFILE);
  };

  const handleUpdateCurrentProject = (projectUuid) => {
    setProject(projects.find((project) => project.uuid === projectUuid));
  };

  const handleUpdateQuota = debounce((e) => {
    const value = parseInt(e.target.value);
    updateUserByProjectUuid(profileInfo.uuid, { quota: value });
  }, 500);

  const handleRemoveFromProject = () => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      title: 'Подтвердите удаление из этого проекта',
      content: `Вы уверены, что хотите удалить этого пользователя из этого проекта?`,
      onOk: async () => {
        try {
          await userAPIs.removeFromProject(profileInfo.uuid, projectUuid);
          createNotification('Удалить успешно', 'success');
          setTimeout(() => {
            navigate(`/projects/${projectUuid}/users`);
          }, 500);
        } catch (error) {
          console.error(error);
          createErrorNotification(error.message.error);
        }
      },
    });
  };

  const canAccrediteUser = ability.can(ACCESSES.ACCREDITE_USER);
  const canBlockUser = ability.can(ACCESSES.BLOCK_USER);
  const canChangeRole = ability.can(ACCESSES.CHANGE_ROLE);
  const canEditUser = ability.can(ACCESSES.EDIT_USER);

  return (
    <>
      <Stack>
        <HeaderSection>проекты</HeaderSection>
        <Stack gap="16px">
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={projects}
            getOptionLabel={(option) => getLocalizationValue(option, 'fullName')}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <ProjectCheckBox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={selected}
                />
                {getLocalizationValue(option, 'fullName')}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Все проекты" />}
            defaultValue={initialValues.projectUuids}
            onChange={(_, value) => handleUpdateProject(value)}
            disabled={!canEditUser}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default ProfileSettings;
