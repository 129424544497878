import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Delete } from '@mui/icons-material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Button, FormHelperText, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'src/@core/components/mui/select';
import Loader from 'src/components/loader';
import { LOCALIZATION, MESSAGES } from 'src/utils/constant';
import { validatorMessage } from 'src/utils/formValidator';
import { createNotification } from 'src/utils/notifications';
import { request } from 'src/utils/request';
import * as yup from 'yup';

function OfflineScubForm({ projectUuid, scubData, refetchScubData }) {
  return (
    <Stack>
      {scubData.length < 1 ? (
        <Stack my={5}>
          <Loader />
        </Stack>
      ) : (
        <Stack mt={5} gap={10}>
          <Typography variant="body1" fontWeight={600}>
            Количество бейджей системы контроля доступа
          </Typography>
          {scubData?.map((el) => {
            return (
              <Stack direction="row" flexGrow={1} sx={{ pointerEvents: 'none' }}>
                <Stack direction="row" flexGrow={1} margin="auto">
                  <TextField label="Роль" value={el.role.name?.ru_RU} fullWidth={true} />
                  <ArrowRightAltIcon fontSize="large" sx={{ height: '56px' }} />
                </Stack>
                <Stack gap={'12px'} direction={{ sm: 'column', md: 'row' }}>
                  <TextField label="Всего" value={el.busy + el.free} fullWidth />
                  <TextField label="Занято" value={el.busy} fullWidth />
                  <TextField label="Свободно" value={el.free} fullWidth />
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
}

export default OfflineScubForm;
