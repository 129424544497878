import { Add, Delete } from '@mui/icons-material';
import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import ButtonLoading from 'src/components/button-loading';
import Input from 'src/components/input';
import Select from 'src/components/select';
import { useProfile } from 'src/context/ProfileContext';
import { updateUserByProjectUuid } from 'src/store/actions/application';
import eventBus, { EVENTS } from 'src/utils/eventBus';
import { createErrorNotification } from 'src/utils/notifications';

let isTyping;
function QuotaByRole({ userUuid, quotaByRole, canEditUser }) {
  const roles = useSelector((state) => state.application.roles);

  const { profile } = useProfile();
  const invitations = profile?.invitations || {};

  const [roleFields, setRoleFields] = useState([]);
  const [cacheQuota, setCacheQuota] = useState({});

  const form = useForm();
  const formState = useFormState();

  const handleSubmit = () => {};

  const handleFormChange = async (values, roleFields) => {
    if (Object.keys(values).every((fieldId) => values[fieldId] === cacheQuota[fieldId])) return;

    let quotaByRole = {};
    let canUpdate = true;
    roleFields.forEach((roleId) => {
      const roleValue = values[`role-${roleId}`];
      const quotaValue = parseInt(values[`quota-${roleId}`]);
      if (roleValue && quotaValue) {
        quotaByRole = { ...quotaByRole, [roleValue]: quotaValue };
      } else {
        canUpdate = false;
      }
    });
    if (
      (canUpdate &&
        Object.keys(quotaByRole).length > 0 &&
        Object.values(quotaByRole).filter((quota) => !!quota).length > 0) ||
      Object.keys(quotaByRole).length === 0
    ) {
      await updateUserByProjectUuid(userUuid, { quotaByRole });
      setCacheQuota(values);
      eventBus.emit(EVENTS.RELOAD_INVITATION_LINKS);
    }
    if (!canUpdate) {
      createErrorNotification('Пожалуйста, заполните все поля');
    }
  };

  const onChangeField = (values, roleFields) => {
    clearTimeout(isTyping);
    isTyping = setTimeout(() => {
      handleFormChange(values, roleFields);
    }, 1000);
  };

  useEffect(() => {
    let roleFields = [];
    Object.keys(quotaByRole).forEach((roleUuid) => {
      const fieldId = uniqueId('role-');
      roleFields.push(fieldId);
      form.change(`role-${fieldId}`, roleUuid);
      form.change(`quota-${fieldId}`, quotaByRole[roleUuid]);
      form.change(`leftCount-${fieldId}`, invitations[roleUuid]?.[0]?.leftCount);
    });
    setRoleFields(roleFields);
  }, [quotaByRole, invitations]);

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap="16px">
        {roleFields.length > 0 && (
          <Typography variant="body1" fontWeight={600}>
            квоты на добавление
          </Typography>
        )}
        <Stack gap="20px">
          <Grid container spacing={4}>
            {roleFields.map((roleId, index) => (
              <React.Fragment key={index}>
                <Grid item xs={formState.values[`leftCount-${roleId}`] ? 12 : 7}>
                  <Select
                    name={`role-${roleId}`}
                    label="Роль участника"
                    options={roles}
                    shrink={false}
                    getOptionLabel={(item) => item.name?.ru_RU}
                    getOptionValue={(item) => item.id}
                    onChange={(e) => {
                      form.change(`role-${roleId}`, e?.target?.value);
                      // onChangeField(form.getState().values, roleFields);
                    }}
                    disabled={!canEditUser}
                  />
                </Grid>
                <Grid item xs={formState.values[`leftCount-${roleId}`] ? 4 : 3}>
                  <Input
                    name={`quota-${roleId}`}
                    label="Квота"
                    shrink={false}
                    type="number"
                    onChange={(e) => {
                      form.change(`quota-${roleId}`, Math.max(0, parseInt(e?.target?.value)));
                      // onChangeField(form.getState().values, roleFields);
                    }}
                    disabled={!canEditUser}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    name={`leftCount-${roleId}`}
                    label="Осталось"
                    shrink={false}
                    type="number"
                    onChange={(e) => {
                      form.change(`leftCount-${roleId}`, Math.max(0, parseInt(e?.target?.value)));
                      // onChangeField(form.getState().values, roleFields);
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', height: 'fit-content', margin: 'auto' }}>
                  <IconButton
                    onClick={() => {
                      const newRoleFields = roleFields.filter((role) => role !== roleId);
                      setRoleFields(newRoleFields);
                      // onChangeField(form.getState().values, newRoleFields);
                    }}
                    disabled={!canEditUser}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
          {canEditUser && (
            <Button variant="outlined" onClick={() => setRoleFields(roleFields.concat([uniqueId('role__')]))}>
              <Add />
            </Button>
          )}
        </Stack>
        {canEditUser && (
          <ButtonLoading variant="contained" onClick={() => handleFormChange(form.getState().values, roleFields)}>
            Сохранить
          </ButtonLoading>
        )}
      </Stack>
    </form>
  );
}

export default QuotaByRole;
